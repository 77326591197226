'use client';

import { useEffect, useState } from 'react';

import clsx from 'clsx';

import { browserslistUseragentRegexp } from '@string/utils/browserslist-useragent-regexp';

import styles from './browser-check.module.scss';

export const BrowserCheck: React.FC = () => {
  const [isOld, setIsOld] = useState(false);
  useEffect(() => {
    if (
      typeof navigator !== 'undefined' &&
      'userAgent' in navigator &&
      !!navigator.userAgent &&
      !/(iPhone|Android)/g.test(navigator.userAgent)
    ) {
      setIsOld(!browserslistUseragentRegexp.test(navigator.userAgent));
    }
  }, []);

  if (isOld) {
    return (
      <aside className={styles.root}>
        <p className={clsx('container', 'text__small', styles.content)}>
          It seems you are using an outdated web browser. For the best
          experience, please update your browser to the latest version or
          consider using a modern web browser like{' '}
          <a
            href="https://www.mozilla.org/firefox/"
            rel="external nofollow noreferrer"
            target="_blank"
          >
            Mozilla Firefox
          </a>
          ,{' '}
          <a
            href="https://www.microsoft.com/edge/"
            rel="external nofollow noreferrer"
            target="_blank"
          >
            Microsoft Edge
          </a>
          , or{' '}
          <a
            href="https://www.google.com/chrome/"
            rel="external nofollow noreferrer"
            target="_blank"
          >
            Google Chrome
          </a>
          .
        </p>
      </aside>
    );
  }

  return null;
};
