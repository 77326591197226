'use client';
import { memo, useId } from 'react';

import dynamic from 'next/dynamic';
import Link from 'next/link';

import clsx from 'clsx';

import type {
  FooterColumnStoryblok,
  LinkStoryblok,
} from '@string/types/generated-storyblok-types';

import styles from './footer-accordion.module.scss';

const Accordion = dynamic(() =>
  import('@string/components/accordion/accordion').then((mod) => mod.Accordion)
);

const AccordionItem = dynamic(() =>
  import('@string/components/accordion-item/accordion-item').then(
    (mod) => mod.AccordionItem
  )
);

const AccordionItemString = dynamic(() =>
  import('@string/components/accordion-item-string/accordion-item-string').then(
    (mod) => mod.AccordionItemString
  )
);

interface FooterAccordionProps {
  items?: FooterColumnStoryblok[];
}

export const FooterAccordion: React.FC<FooterAccordionProps> = memo(
  ({ items }) => {
    const uid = useId();

    function linkMapper(link?: LinkStoryblok | null) {
      if (!link?.link) {
        return null;
      }
      return (
        <Link
          key={`FooterAccordion-${uid}-${link._uid}`}
          className="text__body"
          href={link.link.url || ''}
        >
          {link.label}
        </Link>
      );
    }
    function itemMapper(item: FooterColumnStoryblok, index: number) {
      if (!item?.title || !item?.links) {
        return null;
      }

      const key = `FooterAccordion-${uid}-${index}-${item._uid}`;
      return (
        <AccordionItem key={key} trigger={item.title} value={key}>
          <div className={clsx(styles['link-container'])}>
            {item.links?.map(linkMapper)}
          </div>
          {/* <AccordionItemHeading>
          <AccordionItemButton className={clsx(styles['accordion__button'])}>
            <span className={clsx('title')}>{item.title}</span>
            <AccordionItemState>
              {({ expanded }) => (
                <span className={clsx(expanded && 'icon--expanded')}>
                  <CaretDownSvg
                    aria-hidden={true}
                    className="icon__md"
                    focusable={false}
                  />
                </span>
              )}
            </AccordionItemState>
          </AccordionItemButton>
        </AccordionItemHeading>
        <AccordionItemPanel className={clsx(styles['accordion__panel'])}>
          <div className={clsx(styles['link-container'])}>
            {item.links?.map((link) => (
              <Link
                key={`FooterAccordion-${uid}-${link._uid}`}
                href={link.link?.url || ''}
              >
                {link.label}
              </Link>
            ))}
          </div>
        </AccordionItemPanel> */}
        </AccordionItem>
      );
    }

    return <Accordion>{items?.map(itemMapper)}</Accordion>;
  }
);
