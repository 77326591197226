'use client';
import dynamic from 'next/dynamic';

const SearchDialog = dynamic(() =>
  import('../search-dialog/search-dialog').then((mod) => mod.SearchDialog)
);

export const SearchDialogLoader: React.FC<any> = (props) => {
  return <SearchDialog {...props} />;
};
