'use client';
import { forwardRef } from 'react';

import Link from 'next/link';
import { useRouter } from 'next/navigation';

import clsx from 'clsx';

import { CaretDownSvg } from '@string/svg/server';

import styles from './section-link.module.scss';

export type SectionLinkProps = React.AnchorHTMLAttributes<HTMLAnchorElement>;

export const SectionLink: React.FC<SectionLinkProps> = forwardRef<
  HTMLAnchorElement,
  SectionLinkProps
>(
  (
    {
      children,
      className,
      href = '',
      onClick: onClickInput,
      onKeyDown: onKeyDownInput,
      ...props
    },
    ref
  ) => {
    const router = useRouter();

    const onKeyDown: React.KeyboardEventHandler<HTMLAnchorElement> = (
      event
    ) => {
      onKeyDownInput?.(event);
      if (event.key === 'Enter' || event.key === ' ') {
        onClickInput?.(event as unknown as React.MouseEvent<HTMLAnchorElement>);
        event.preventDefault();
        router.push(href);
      }
    };
    return (
      <Link
        ref={ref}
        className={clsx('text__navigation', styles.root, className)}
        href={href}
        onKeyDown={onKeyDown}
        {...props}
      >
        <span className={clsx('text__navigation', styles['label'])}>
          {children}
        </span>
        <CaretDownSvg
          aria-hidden={true}
          className={styles['icon']}
          focusable={false}
          height={20}
          width={20}
        />
      </Link>
    );
  }
);
