'use client';

import { memo, useEffect, useMemo } from 'react';

import { sendGTMEvent } from '@next/third-parties/google';
import * as Dialog from '@radix-ui/react-dialog';
import algoliasearch from 'algoliasearch/lite';
import clsx from 'clsx';
import {
  Hits,
  SearchBox,
  Stats,
  StatsProps,
  useInstantSearch,
} from 'react-instantsearch';
import { InstantSearchNext } from 'react-instantsearch-nextjs';
import useDebounce from 'react-use/lib/useDebounce';

import { useModal } from '@string/context/modal/use-modal';
import { default as CloseSvg } from '@string/svg/close.svg';
import { default as SearchSvg } from '@string/svg/search.svg';
import { replacePlaceholders } from '@string/utils/string/replace-placeholders';

import { IconButtonRef } from '../icon-button/icon-button';
import { ModalHeader } from '../modal-header/modal-header';
import { ModalOverlay } from '../modal-overlay/modal-overlay';
import { SearchHit } from '../search-hit/search-hit';
import { Spinner } from '../spinner/spinner';

import styles from './search-dialog.module.scss';

import type {
  IconProps,
  SearchBoxClassNames,
} from 'react-instantsearch/dist/es/ui/SearchBox';

const ALGOLIA_APP_ID = 'RB4UYKQ31W';
const ALGOLIA_API_KEY = 'b05d68a5a6d26e9fa4cd1ba57a41952e';
const ALGOLIA_INDEX = 'dev_STORYBLOK';

const searchClient = algoliasearch(ALGOLIA_APP_ID, ALGOLIA_API_KEY);

const searchBoxClassNames: Partial<SearchBoxClassNames> = {
  form: styles['search-box-form'],
  input: styles['search-box-input'],
  loadingIcon: styles['search-box-loading-icon'],
  loadingIndicator: styles['search-box-loading-indicator'],
  reset: styles['search-box-reset'],
  resetIcon: styles['search-box-reset-icon'],
  root: styles['search-box-root'],
  submit: styles['search-box-submit'],
  submitIcon: styles['search-box-submit-icon'],
};
const hitsBoxClassNames = {
  bannerImage: styles['hits-banner-image'],
  bannerLink: styles['hits-banner-link'],
  bannerRoot: styles['hits-banner-root'],
  emptyRoot: styles['hits-empty-root'],
  item: styles['hits-item'],
  list: styles['hits-list'],
  root: styles['hits-root'],
};

interface SearchDialogProps {
  className?: string;
  style?: React.CSSProperties;
  open?: boolean;
  searchStats?: string;
  resetButtonTitle?: string;
  submitButtonTitle?: string;
  title?: string;
  closeLabel?: string;
  searchLabel?: string;
}

const ResetIcon: React.FC<IconProps> = ({ classNames }) => {
  return (
    <CloseSvg
      aria-hidden={true}
      className={classNames?.resetIcon}
      focusable={false}
      height={24}
      width={24}
    />
  );
};

const SearchIcon: React.FC<IconProps> = ({ classNames }) => {
  return (
    <SearchSvg
      aria-hidden={true}
      className={classNames?.submitIcon}
      focusable={false}
      height={24}
      width={24}
    />
  );
};

export const SearchDialog: React.FC<SearchDialogProps> = memo(
  ({
    className,
    style,
    searchStats,
    resetButtonTitle,
    submitButtonTitle,
    title = 'Search',
    closeLabel = 'Close',
    searchLabel = 'Search',
  }) => {
    const { open, setOpen } = useModal();

    const onOpenChange = (_open: boolean) => {
      setOpen(undefined);
    };

    return (
      <Dialog.Root onOpenChange={onOpenChange} open={open}>
        <Dialog.Trigger asChild>
          <IconButtonRef aria-label={searchLabel} className={styles.trigger}>
            <span className="text__navigation">{searchLabel}</span>
            <SearchSvg
              aria-hidden={true}
              focusable={false}
              height={20}
              width={20}
            />
          </IconButtonRef>
        </Dialog.Trigger>
        <Dialog.Portal>
          <ModalOverlay className={styles.overlay} />
          <Dialog.Content className={clsx(styles.content)}>
            <div className={clsx(styles.inner, className)} style={style}>
              <ModalHeader closeLabel={closeLabel} title={title} hideTitle />
              <InstantSearchNext
                indexName={ALGOLIA_INDEX}
                searchClient={searchClient}
              >
                <SearchDialogContent
                  resetButtonTitle={resetButtonTitle}
                  searchStats={searchStats}
                  submitButtonTitle={submitButtonTitle}
                />
              </InstantSearchNext>
            </div>
          </Dialog.Content>
        </Dialog.Portal>
      </Dialog.Root>
    );
  }
);
interface SearchDialogContentProps {
  resetButtonTitle?: string;
  submitButtonTitle?: string;
  searchStats?: string;
}
export const SearchDialogContent: React.FC<SearchDialogContentProps> = ({
  searchStats = '%h [result|results] found for term "%q"',
  resetButtonTitle = 'Reset',
  submitButtonTitle = 'Search',
}) => {
  const { status, uiState } = useInstantSearch();
  const term = useMemo(() => uiState?.[ALGOLIA_INDEX]?.query ?? '', [uiState]);
  const [, cancel] = useDebounce(
    () => {
      if (term?.length > 3) {
        sendGTMEvent({
          event: 'search',
          searchTerm: term,
        });
      }
    },
    600,
    [term]
  );

  useEffect(() => {
    return () => {
      cancel?.();
    };
  }, []);

  function getSingularPlural(input: string): {
    pattern: RegExp;
    singular: string;
    plural: string;
  } {
    const pattern = /\[(\w+?)\|(\w+?)\]/;
    const [, singular = '', plural = ''] = [...(input.match(pattern) || [])];
    return {
      pattern,
      plural,
      singular,
    };
  }

  const statsTranslations: StatsProps['translations'] = {
    rootElementText: ({ nbHits }) => {
      if (!term) {
        return '';
      }
      const base = searchStats || '%h [result|results] found for term "%q"';

      const { pattern, singular, plural } = getSingularPlural(base);

      return replacePlaceholders<string>(base, [
        ['%h', nbHits.toString()],
        ['%q', term],
        [pattern, nbHits === 1 ? singular : plural],
      ]);
    },
  };

  return (
    <>
      <div className={styles.input}>
        <SearchBox
          className={clsx(styles.searchbox)}
          classNames={searchBoxClassNames}
          resetIconComponent={ResetIcon}
          submitIconComponent={SearchIcon}
          translate="yes"
          translations={{
            resetButtonTitle,
            submitButtonTitle,
          }}
        />
        <div
          className={clsx(
            styles.loading,
            status === 'loading' && styles['loading--show']
          )}
        >
          <Spinner className={styles.spinner} />
        </div>
        <Stats
          className={clsx('text__small', styles.stats)}
          translate="yes"
          translations={statsTranslations}
        />
      </div>
      <div className={styles.results}>
        <Hits classNames={hitsBoxClassNames} hitComponent={SearchHit} />
      </div>
    </>
  );
};
