import { i18nConfig } from './i18n-config';
import { markets } from './market-map';

export function getLocaleForCountry(country: string) {
  const parts = country.split('-');
  const value = parts[parts.length - 1]?.toLowerCase();
  const market = markets.find(({ code }) => value && code === value);
  if (market) {
    return `${market.languages[0]}-${market.code}`;
  }

  return i18nConfig.defaultLocale;
}
