'use client';
import { memo } from 'react';

import { Accordion } from 'react-accessible-accordion';

import { useCart } from '@string/context/cart/use-cart';
import type {
  ByoDesign,
  ExtendedByoDesignItem,
} from '@string/types/cart/byo-design';
import type { ByoDesignArticle } from '@string/types/cart/byo-design-article';
import type { LineItem } from '@string/types/cart/line-item';
import { SiteLocale } from '@string/types/site';

import { AccordionItemString } from '../accordion-item-string/accordion-item-string';

import { ByoDesignItem, CartLineItem } from './partials';

interface CartItemsProps {
  locale: SiteLocale;
  cartPage: boolean;
}

export const CartItems: React.FC<CartItemsProps> = memo(
  ({ locale, cartPage }) => {
    const {
      byoDesigns,
      byoSectionLabel,
      itemsSectionLabel,
      lineItems,
      cartIsEmpty,
    } = useCart();

    function byoDesignsMapper(item: ByoDesign) {
      const byoItem: ExtendedByoDesignItem = {
        ...item,
        lineItems: item.articles
          ?.map((o: ByoDesignArticle) => {
            const matchingLineItem = lineItems?.find((lineItem) => {
              return lineItem.variant_id === o.variant_id;
            });
            return matchingLineItem
              ? {
                  ...matchingLineItem,
                  quantity: o.quantity,
                }
              : null;
          })
          .filter((o) => o) as LineItem[],
      };
      return (
        <ByoDesignItem
          key={`Byo-${item.design_id}`}
          cartPage={cartPage}
          item={byoItem}
          locale={locale}
        />
      );
    }

    function lineItemMapper(item: LineItem) {
      return (
        <CartLineItem
          key={`Cart-${item.id}`}
          cartPage={cartPage}
          item={item}
          locale={locale}
        />
      );
    }

    if (cartIsEmpty) {
      return null;
    }

    return (
      <Accordion
        preExpanded={['cart-items']}
        allowMultipleExpanded
        allowZeroExpanded
      >
        {!!lineItems?.length && (
          <AccordionItemString title={itemsSectionLabel} uuid="cart-items">
            <ul>{lineItems?.map(lineItemMapper)}</ul>
          </AccordionItemString>
        )}
        {!!byoDesigns?.length && (
          <AccordionItemString title={byoSectionLabel} uuid="byo-designs">
            <ul>{byoDesigns?.map(byoDesignsMapper)}</ul>
          </AccordionItemString>
        )}
      </Accordion>
    );
  }
);
