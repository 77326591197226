'use client';

import { useMemo, useRef, useState } from 'react';

import { usePathname } from 'next/navigation';

import * as Dialog from '@radix-ui/react-dialog';
import clsx from 'clsx';
import { setCookie } from 'tiny-cookie';

import { useLocale } from '@string/hooks/use-locale/use-locale';
import { default as CloseSvg } from '@string/svg/close.svg';
import { default as GlobeSvg } from '@string/svg/globe.svg';
import { default as SearchSvg } from '@string/svg/search.svg';
import { MarketData, StoryblokLanguage } from '@string/types/site';
import { LOCALE_PREFERENCE_COOKIE } from '@string/utils/cookies';
import { markets } from '@string/utils/locale/market-map';
import { storyblokLanguages } from '@string/utils/locale/storyblok-lang-map';

import { ButtonLargeLink } from '../button-large/button-large';
import { IconButton } from '../icon-button/icon-button';
import { InputText } from '../input-text/input-text';
import { ModalHeader } from '../modal-header/modal-header';
import { ModalOverlay } from '../modal-overlay/modal-overlay';

import styles from './locale-selector-dialog.module.scss';

export interface LocaleSelectorDialogI18n {
  title: string;
  close: string;
  save: string;
  language: string;
  placeholder: string;
  clear: string;
}

interface LocaleSelectorDialogProps {
  className?: string;
  i18n: LocaleSelectorDialogI18n;
  style?: React.CSSProperties;
  small?: boolean;
}

interface FlattenedMarket extends Omit<MarketData, 'languages'> {
  language: string;
  tag: string;
  title?: string;
}

const marketDataMapper = ({ languages, ...market }: MarketData) => {
  const languagesMapper = (language: string) => {
    const languageFinder = ({ code }: StoryblokLanguage) => code === language;
    const languageData = storyblokLanguages.find(languageFinder);
    const name = [
      market.localNames?.[language] ?? market.localName ?? market.name,
      languageData?.localName ?? languageData?.name,
    ].join(' | ');
    const title = [market.name, languageData?.name].join(' | ');
    return {
      ...market,
      language,
      name,
      tag: `${language}-${market.code}`,
      title,
    };
  };
  return languages.map(languagesMapper);
};

export const LocaleSelectorDialog: React.FC<LocaleSelectorDialogProps> = ({
  className,
  style,
  i18n,
  small,
}) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const pathname = usePathname();
  const [term, setTerm] = useState('');
  const locale = useLocale();
  const [selected, setSelected] = useState<string>(locale.localeTag);
  const href = useMemo(() => {
    const pattern = new RegExp(`^/${locale.localeTag}`);
    return pathname.replace(pattern, `/${selected}`);
  }, [pathname, selected]);

  const flattenedMarkets: FlattenedMarket[] = useMemo(() => {
    const workingSet = markets.flatMap(marketDataMapper);
    const sorted = workingSet.filter((item) => item.tag !== locale.localeTag);
    const current = workingSet.find((item) => item.tag === locale.localeTag);
    if (current) {
      sorted.unshift(current);
    }

    return sorted;
  }, [markets, term]);

  const filteredMarkets: FlattenedMarket[] = useMemo(() => {
    return !term
      ? flattenedMarkets
      : flattenedMarkets.filter(
          ({ name, title }) =>
            name.toLowerCase().includes(term.toLowerCase()) ||
            title?.toLowerCase().includes(term.toLowerCase())
        );
  }, [markets, term]);

  const active = useMemo(
    () =>
      flattenedMarkets.find(
        ({ code, language }) =>
          code === locale.country_code && language === locale.language
      ),
    [locale]
  );

  const label = useMemo(() => {
    const long = active?.name || i18n.title;
    const market = markets.find((item) => item.code === active?.code);
    if (!small || !market) {
      return long;
    }

    let short =
      market.localNames?.[active?.language || ''] ||
      market.localName ||
      market.shortName ||
      market.name ||
      '';
    const language = storyblokLanguages.find(
      ({ code }) => code === active?.language
    );

    if (
      market.languages.length > 1 &&
      (short === market.shortName || short === market.name) &&
      !!language
    ) {
      short += ` | ${language.localName || language.name}`;
    }

    return short;
  }, [active, i18n, small]);

  function marketMapper(market: FlattenedMarket) {
    const key = `LocaleSelectorDialog-market-${market.tag}`;
    const onChange = () => {
      setSelected(market.tag);
    };
    return (
      <label key={key} className={styles.option}>
        <input
          checked={selected === market.tag}
          className={styles.radio}
          name="market"
          onChange={onChange}
          type="radio"
          value={market.tag}
        />
        <span className={styles.label} title={market.title}>
          {market.name}
        </span>
      </label>
    );
  }

  const onOpenChange = (open: boolean) => {
    if (open) {
      setSelected(locale.localeTag);
      setTerm('');
    }
  };

  const onSearchChange: React.ChangeEventHandler<HTMLInputElement> = (
    event
  ) => {
    setTerm(event.target.value);
  };

  const inputStyle = {
    '--inline-size': '100%',
    '--input-inline-size': '100%',
    '--input-padding-inline': '0 3.5rem',
  } as React.CSSProperties;

  const onClearClick = () => {
    setTerm('');
  };

  const onSaveClick = () => {
    setCookie(LOCALE_PREFERENCE_COOKIE, selected);
  };

  const iconSize = small ? 20 : 32;

  const onOpenAutoFocus = () => {
    // Hack for focus
    setTimeout(() => {
      inputRef.current?.focus();
    }, 1);
  };

  return (
    <Dialog.Root onOpenChange={onOpenChange}>
      <Dialog.Trigger
        aria-label={i18n.title}
        className={clsx('text__small', styles.trigger, small && styles.small)}
      >
        <GlobeSvg
          aria-hidden={true}
          focusable={false}
          height={iconSize}
          width={iconSize}
        />
        <span className={clsx(styles['trigger-label'], small && styles.small)}>
          {label}
        </span>
      </Dialog.Trigger>
      <Dialog.Portal>
        <ModalOverlay className={styles.overlay} />
        <Dialog.Content
          className={clsx(styles.content, className)}
          onOpenAutoFocus={onOpenAutoFocus}
          style={style}
        >
          <ModalHeader
            className={styles.header}
            closeLabel={i18n.close}
            title={i18n.title}
          />
          <div className={styles.inner}>
            <div className={styles.search}>
              <InputText
                ref={inputRef}
                autoComplete="off"
                label={i18n.placeholder}
                onChange={onSearchChange}
                style={inputStyle}
                value={term}
                simple
              />
              <IconButton
                aria-label={i18n.clear}
                className={clsx(
                  styles.clear,
                  !!term.length && styles['clear--show']
                )}
                onClick={onClearClick}
              >
                <CloseSvg
                  aria-hidden={true}
                  focusable={false}
                  height={20}
                  width={20}
                />
              </IconButton>
              <span className={styles['search-icon']}>
                <SearchSvg
                  aria-hidden={true}
                  focusable={false}
                  height={20}
                  width={20}
                />
              </span>
            </div>
            <div className={styles.list} tabIndex={-1}>
              {filteredMarkets.map(marketMapper)}
            </div>
            <div className={styles.actions}>
              <ButtonLargeLink href={href} onClick={onSaveClick} wide>
                {i18n.save}
              </ButtonLargeLink>
            </div>
          </div>
        </Dialog.Content>
      </Dialog.Portal>
    </Dialog.Root>
  );
};
