import { useMemo } from 'react';

import clsx from 'clsx';

import { useCart } from '@string/context/cart/use-cart';
import { MinusSvg, PlusSvg } from '@string/svg';

import styles from './cart-item-control.module.scss';

interface CartItemControlProps {
  add(): void;
  sub(): void;
  quantity: number | null;
}

export const CartItemControl: React.FC<CartItemControlProps> = ({
  add,
  sub,
  quantity,
}) => {
  const { increaseQuantity, decreaseQuantity, removeFromCart } = useCart();
  const decreaseLabel = useMemo(
    () => (quantity && quantity > 1 ? decreaseQuantity : removeFromCart),
    [quantity]
  );
  const size = 20;
  return (
    <div className={styles.root}>
      <button
        aria-label={decreaseLabel}
        className={clsx(styles.button)}
        onClick={sub}
        title={decreaseLabel}
      >
        <MinusSvg
          aria-hidden={true}
          className={clsx(styles.icon)}
          focusable={false}
          height={size}
          width={size}
        />
      </button>
      <span className={clsx('text__small')}>{quantity}</span>
      <button
        aria-label={increaseQuantity}
        className={clsx(styles.button)}
        onClick={add}
        title={increaseQuantity}
      >
        <PlusSvg
          aria-hidden={true}
          className={clsx(styles.icon)}
          focusable={false}
          height={size}
          width={size}
        />
      </button>
    </div>
  );
};
