import { forwardRef } from 'react';

import clsx from 'clsx';

import { IconButtonRef } from '../icon-button/icon-button';
import { Spinner } from '../spinner/spinner';

import styles from './cart-trigger.module.scss';

interface CartTriggerProps extends React.HTMLAttributes<HTMLButtonElement> {
  children?: number;
  loading?: boolean;
}

export const CartTrigger = forwardRef<HTMLButtonElement, CartTriggerProps>(
  ({ children, className, loading, ...props }, ref) => {
    return (
      <IconButtonRef
        ref={ref}
        className={clsx('text__small', styles.root, className)}
        {...props}
      >
        {loading && (
          <Spinner
            className={clsx(
              styles.spinner,
              children && children > 0 && styles.discreet
            )}
          />
        )}
        <span className={styles.inner}>
          <span
            className={clsx(
              styles.count,
              loading && children === 0 && styles.hide
            )}
          >
            {children}
          </span>
        </span>
      </IconButtonRef>
    );
  }
);
