'use client';
import { useEffect, useState } from 'react';

import * as Toast from '@radix-ui/react-toast';
import clsx from 'clsx';

import { useCart } from '@string/context/cart/use-cart';
import { default as CloseSvg } from '@string/svg/close.svg';
import { replacePlaceholders } from '@string/utils/string/replace-placeholders';

import { IconButtonRef } from '../icon-button/icon-button';

import styles from './cart-toast.module.scss';

export const CartToast: React.FC = () => {
  const duration = 3000;
  const {
    addedQueue,
    productAddedToCart,
    setAddedQueue,
    closeLabel,
    cartUpdated,
  } = useCart();
  const [toastOpen, setToastOpen] = useState<boolean[]>([]);
  useEffect(() => {
    setToastOpen(addedQueue.map((_) => true));
  }, [addedQueue]);

  const itemMapper = (item: string, index: number) => {
    const onOpenChange = (open: boolean) => {
      setToastOpen((prev) => {
        const newOpen = [...prev];
        newOpen[index] = open;
        return newOpen;
      });
    };

    const onAnimationEndFilter = (_: string, i: number) => i !== index;
    const onAnimationEnd: React.AnimationEventHandler = () => {
      if (!toastOpen[index]) {
        setAddedQueue((prev) => prev.filter(onAnimationEndFilter));
      }
    };
    const toastMessage = item
      ? replacePlaceholders<string>(productAddedToCart, [['%p', item]])
      : '';
    return (
      <Toast.Root
        key={`CartToast-${index}`}
        className={styles.root}
        onAnimationEndCapture={onAnimationEnd}
        onOpenChange={onOpenChange}
        open={toastOpen[index]}
      >
        <Toast.Close asChild>
          <IconButtonRef
            aria-label={closeLabel}
            className={clsx(styles.close)}
            title={closeLabel}
          >
            <CloseSvg
              aria-hidden={true}
              focusable={false}
              height={24}
              width={24}
            />
          </IconButtonRef>
        </Toast.Close>
        <Toast.Title className={clsx('text__small', styles.title)}>
          {cartUpdated}
        </Toast.Title>
        {toastMessage && (
          <Toast.Description asChild>
            <div
              className={clsx(
                'text__small',
                'text__small--light',
                styles.description
              )}
            >
              {toastMessage}
            </div>
          </Toast.Description>
        )}
      </Toast.Root>
    );
  };

  return (
    <Toast.Provider duration={duration} swipeDirection="right">
      {addedQueue.map(itemMapper)}

      <Toast.Viewport className={styles.viewport} />
    </Toast.Provider>
  );
};
