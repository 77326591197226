'use client';

import * as Dialog from '@radix-ui/react-dialog';
import clsx from 'clsx';

import { useCart } from '@string/context/cart/use-cart';
import { useLocale } from '@string/hooks/use-locale/use-locale';

import { CartItems } from '../cart-items/cart-items';
import { CartSummary } from '../cart-summary/cart-summary';
import { CartTrigger } from '../cart-trigger/cart-trigger';
import { ModalHeader } from '../modal-header/modal-header';
import { ModalOverlay } from '../modal-overlay/modal-overlay';

import styles from './cart.module.scss';

interface CartProps {
  className?: string;
  style?: React.CSSProperties;
}

export const Cart: React.FC<CartProps> = ({ className, style }) => {
  const locale = useLocale();
  const { closeLabel, cartLabel, cartIsEmpty, emptyLabel, loading, cartTotal } =
    useCart();

  if (!locale.isShopEnabled) {
    return null;
  }

  return (
    <Dialog.Root>
      <Dialog.Trigger asChild>
        <CartTrigger
          aria-label={cartLabel}
          className={styles.trigger}
          loading={loading}
        >
          {cartTotal}
        </CartTrigger>
      </Dialog.Trigger>
      <Dialog.Portal>
        <ModalOverlay className={styles.overlay} />
        <Dialog.Content
          className={clsx(styles.content, className)}
          style={style}
        >
          <div className={clsx(styles.body)}>
            <ModalHeader
              className={styles.header}
              closeLabel={closeLabel}
              title={cartLabel}
            />
            <div className={styles.inner}>
              <div className={styles.list}>
                {cartIsEmpty && (
                  <div className={clsx('text__navigation', styles.empty)}>
                    {emptyLabel}
                  </div>
                )}

                <CartItems cartPage={false} locale={locale} />
              </div>
              <CartSummary locale={locale} />
            </div>
          </div>
        </Dialog.Content>
      </Dialog.Portal>
    </Dialog.Root>
  );
};
