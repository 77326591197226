import { ExtendedByoDesignItem, LineItem } from '@string/types';
import { SiteLocale } from '@string/types/site';

import { AccordionItemString } from '../../../accordion-item-string/accordion-item-string';
import { CartLineItem } from '../cart-line-item/cart-line-item';

interface ByoDesignItemProps {
  item: ExtendedByoDesignItem;
  locale: SiteLocale;
  cartPage: boolean;
}

export const ByoDesignItem: React.FC<ByoDesignItemProps> = ({
  cartPage,
  item,
  locale,
}) => {
  function itemMapper(item: LineItem) {
    return (
      <CartLineItem
        key={`Cart-${item.id}`}
        cartPage={cartPage}
        item={item}
        locale={locale}
        removable={false}
      />
    );
  }

  return (
    <AccordionItemString title={item.design_id}>
      {item.lineItems?.map(itemMapper)}
    </AccordionItemString>
  );
};
