'use client';

import { CartProvider, CartProviderProps } from '../cart/cart-context';
import { HeaderProvider } from '../header/header-context';
import { ModalProvider } from '../modal/modal-context';

interface ApplicationProviderProps {
  children?: React.ReactNode;
  cart: Omit<CartProviderProps, 'children'>;
}
export const ApplicationProvider: React.FC<ApplicationProviderProps> = ({
  children,
  cart,
}) => {
  return (
    <HeaderProvider>
      <ModalProvider>
        <CartProvider {...cart}>{children}</CartProvider>
      </ModalProvider>
    </HeaderProvider>
  );
};
