import { useMemo } from 'react';

import clsx from 'clsx';

import { useCart } from '@string/context/cart/use-cart';
import { SiteLocale } from '@string/types/site';
import { normalizeLocale } from '@string/utils/normalize-locale/normalize-locale';
import { replacePlaceholders } from '@string/utils/string/replace-placeholders';

import { ButtonLargeLink } from '../button-large/button-large';

import styles from './cart-summary.module.scss';

interface CartSummaryProps {
  locale: SiteLocale;
  className?: string;
}

export const CartSummary: React.FC<CartSummaryProps> = ({
  locale,
  className,
}) => {
  const {
    cart,
    vatLabel,
    checkoutLabel,
    checkoutUrl,
    totalLabel,
    cartIsEmpty,
  } = useCart();

  const incVat = cart?.tax_included;
  const amount = cart?.cart_amount;
  const baseAmount = cart?.base_amount;
  const currency = cart?.currency?.code || '';

  const priceInfo = useMemo(() => {
    if (!incVat) {
      return '';
    }
    const vatString = new Intl.NumberFormat(locale.localeTag, {
      maximumFractionDigits: 2,
      style: 'percent',
      unitDisplay: 'narrow',
    }).format(locale?.vat || 0);
    return replacePlaceholders<string>(vatLabel, [['%d', vatString]]);
  }, [vatLabel, incVat]);

  const value: number = (incVat ? amount : baseAmount) || 0;

  const price = useMemo(() => {
    if (!currency) {
      return '';
    }
    const normalizedLocale = normalizeLocale(locale.localeTag);
    const regex = new RegExp(/^\w+-\w+$/);
    if (normalizedLocale && regex.test(normalizedLocale)) {
      return new Intl.NumberFormat(normalizedLocale, {
        currency,
        currencyDisplay: 'code',
        style: 'currency',
      }).format(value);
    }
    return `${value}`;
  }, [value, locale, currency]);

  if (cartIsEmpty) {
    return null;
  }

  return (
    <div className={clsx(styles.root, className)}>
      <div className={clsx(styles.details)}>
        <div className={clsx('text__body', styles.label)}>{totalLabel}</div>
        <div className={clsx(styles.total)}>
          <span className={clsx('text__list-header', styles.price)}>
            {price}
          </span>
          <span className={clsx('text__small', styles['price-info'])}>
            {priceInfo}
          </span>
        </div>
      </div>
      <div className={clsx(styles.actions)}>
        <ButtonLargeLink href={checkoutUrl} variant="filled" wide>
          {checkoutLabel}
        </ButtonLargeLink>
      </div>
    </div>
  );
};
