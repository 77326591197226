'use client';
import { forwardRef, useMemo, useState } from 'react';

import Link from 'next/link';
import { usePathname, useRouter } from 'next/navigation';

import * as NavigationMenu from '@radix-ui/react-navigation-menu';
import clsx from 'clsx';
import { render } from 'storyblok-rich-text-react-renderer';

import { CaretDownSvg } from '@string/svg/server';
import { MenuChildData, MenuItemData } from '@string/types/menu-item-data';
import { getSizes } from '@string/utils/image/get-sizes';

import { StringImage } from '../string-image/string-image';

import styles from './desktop-menu-item.module.scss';

interface DesktopMenuItemProps {
  item: MenuItemData;
}

export const DesktopMenuItem: React.FC<DesktopMenuItemProps> = ({ item }) => {
  const pathname = usePathname();

  const [current, setCurrent] = useState<number | null>(null);
  const listLabel = useMemo(() => {
    return current && item?.links?.[current]?.label
      ? item.links[current].label
      : item?.label || '';
  }, [item, current]);

  const sizes = getSizes([
    [0, '25vw'],
    [1920, '455px'],
  ]);

  function itemMapper(child: MenuChildData, index: number) {
    const key = `DesktopMenuItem-link-${child._uid}`;
    const isActive = child.href === pathname;

    const onMouseEnter = () => {
      setCurrent(index);
    };
    const onMouseLeave = () => {
      setCurrent(null);
    };

    return (
      <li key={key} role="none">
        <NavigationMenu.Link active={isActive} asChild>
          <DesktopMenuLink
            href={child.href}
            onMouseEnter={onMouseEnter}
            onMouseLeave={onMouseLeave}
          >
            {child.label}
          </DesktopMenuLink>
        </NavigationMenu.Link>
      </li>
    );
  }

  function descriptionMapper(child: MenuChildData, index: number) {
    const key = `DesktopMenuItem-description-${child._uid}`;
    return (
      <div
        key={key}
        className={clsx(
          'text__body',
          styles.description,
          styles['description--counter'],
          index === current && styles['description--active']
        )}
        data-index={index + 1}
      >
        {child?.description && render(child.description)}
      </div>
    );
  }
  function figureMapper(child: MenuChildData, index: number) {
    const key = `DesktopMenuItem-figure-${child._uid}`;
    if (!child.image?.filename) {
      return null;
    }
    return (
      <StringImage
        key={key}
        alt={child.image.alt || child.label || ''}
        className={clsx(
          styles.image,
          index === current && styles['image--active']
        )}
        sizes={sizes}
        src={child.image.filename}
        fill
      />
    );
  }

  return (
    <NavigationMenu.Item>
      <NavigationMenu.Trigger asChild>
        <DesktopMenuTrigger
          className={clsx('text__navigation', styles.trigger)}
          href={item.href}
        >
          {item.label}
        </DesktopMenuTrigger>
      </NavigationMenu.Trigger>
      <NavigationMenu.Content className={styles.content}>
        <div className={clsx('grid', styles.inner)}>
          <ul aria-label={listLabel} className={styles.list} role="menu">
            {item.links.map(itemMapper)}
          </ul>
          <div className={clsx('text__body', styles['description-container'])}>
            {item?.description && (
              <div
                className={clsx(
                  'text__body',
                  styles.description,
                  typeof current !== 'number' && styles['description--active']
                )}
              >
                {render(item.description)}
              </div>
            )}
            {item.links.map(descriptionMapper)}
          </div>
          <figure className={styles.figure}>
            {item?.image && (
              <StringImage
                alt={item.image.alt || item.label || ''}
                className={clsx(
                  styles.image,
                  typeof current !== 'number' && styles['image--active']
                )}
                sizes={sizes}
                src={item.image.filename}
                fill
              />
            )}
            {item.links.map(figureMapper)}
          </figure>
        </div>
      </NavigationMenu.Content>
    </NavigationMenu.Item>
  );
};

type DesktopMenuTriggerProps = React.AnchorHTMLAttributes<HTMLAnchorElement>;

const DesktopMenuTrigger = forwardRef<
  HTMLAnchorElement,
  DesktopMenuTriggerProps
>(
  (
    {
      children,
      className,
      href,
      onClick: radixOnClick,
      onKeyDown: radixOnKeyDown,
      ...props
    },
    forwardedRef
  ) => {
    const onClick: React.MouseEventHandler<HTMLAnchorElement> = (event) => {
      radixOnClick?.(event);
      event.preventDefault();
    };
    // Hack to handle space
    const onKeyDown: React.KeyboardEventHandler<HTMLAnchorElement> = (
      event
    ) => {
      radixOnKeyDown?.(event);
      if (event.key === ' ') {
        onClick(event as unknown as React.MouseEvent<HTMLAnchorElement>);
        event.preventDefault();
      }
    };

    return (
      <Link
        ref={forwardedRef}
        className={clsx('text__navigation', styles.trigger, className)}
        href={href || ''}
        onClick={onClick}
        onKeyDown={onKeyDown}
        {...props}
      >
        {children}
      </Link>
    );
  }
);

export type DesktopMenuLinkProps =
  React.AnchorHTMLAttributes<HTMLAnchorElement>;

export const DesktopMenuLink: React.FC<DesktopMenuLinkProps> = forwardRef<
  HTMLAnchorElement,
  DesktopMenuLinkProps
>(
  (
    {
      children,
      className,
      href = '',
      onClick: radixOnClick,
      onKeyDown: radixOnKeyDown,
      ...props
    },
    ref
  ) => {
    const router = useRouter();

    const onClick: React.MouseEventHandler<HTMLAnchorElement> = (event) => {
      radixOnClick?.(event);
    };

    // Hack to handle space
    const onKeyDown: React.KeyboardEventHandler<HTMLAnchorElement> = (
      event
    ) => {
      radixOnKeyDown?.(event);
      if (event.key === ' ') {
        radixOnClick?.(event as unknown as React.MouseEvent<HTMLAnchorElement>);
        router.push(href);
        event.preventDefault();
      }
    };

    return (
      <Link
        ref={ref}
        className={clsx('text__navigation', styles.link, className)}
        href={href}
        onKeyDown={onKeyDown}
        onClick={onClick}
        {...props}
      >
        {children}
        <CaretDownSvg
          aria-hidden={true}
          className={styles.icon}
          focusable={false}
          height={20}
          width={20}
        />
      </Link>
    );
  }
);
