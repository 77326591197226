'use client';
import { useEffect, useMemo, useRef, useState } from 'react';

import dynamic from 'next/dynamic';
import { usePathname } from 'next/navigation';

import clsx from 'clsx';
import { render } from 'storyblok-rich-text-react-renderer';
import { getCookie, setCookie } from 'tiny-cookie';

import { default as CloseSvg } from '@string/svg/close.svg';
import { DatasourceEntries } from '@string/types/datasource';
import type { RichtextStoryblok } from '@string/types/generated-storyblok-types';
import { SiteLocale } from '@string/types/site';
import { LOCALE_PREFERENCE_COOKIE } from '@string/utils/cookies';
import { getLocaleForCountry } from '@string/utils/locale/get-locale-for-country';
import { replacePlaceholders } from '@string/utils/string/replace-placeholders';

import styles from './locale-modal-content.module.scss';

const revalidate = 3600;

const Heading = dynamic(() =>
  import('@string/components/heading/heading').then((mod) => mod.Heading)
);

const ButtonLarge = dynamic(() =>
  import('@string/components/button-large/button-large').then(
    (mod) => mod.ButtonLarge
  )
);
const ButtonLargeLink = dynamic(() =>
  import('@string/components/button-large/button-large').then(
    (mod) => mod.ButtonLargeLink
  )
);

interface LocalModalContentProps {
  portalId: string;

  title?: string | null;
  body?: RichtextStoryblok | null;
  accept?: string | null;
  reject?: string | null;
  countries: DatasourceEntries;
  locale: SiteLocale;
}

function getCountryName(
  countryCode: string | undefined,
  countries: DatasourceEntries,
  article = false
): string {
  if (!countryCode) {
    return '';
  }
  const name = countries.get(countryCode);
  if (!name) {
    return '';
  }

  if (/^([\w ]+\|[\w ]+)$/i.test(name.trim())) {
    return name.split('|')[article ? 1 : 0];
  }
  return name;
}

export const LocaleModalContent: React.FC<LocalModalContentProps> = ({
  portalId,
  title,
  body,
  accept,
  reject,
  countries,
  locale,
}) => {
  const pathname = usePathname();
  const ref = useRef<Element | null>(null);
  const [isOpen, setIsOpen] = useState(false); // TODO local storage or simular?
  const valid = useMemo(
    () => !!title && !!body && !!accept && !!reject,
    [body, accept, reject]
  );
  const [countryCodes, setCountryCodes] = useState<{
    current: string;
    suggested: string;
  }>({
    current: '',
    suggested: '',
  });
  const placeholdersBody: Map<string, string> = useMemo(() => {
    const current = getCountryName(countryCodes.current, countries);
    const suggested = getCountryName(countryCodes.suggested, countries);

    return new Map<string, string>([
      ['%c', current],
      ['%s', suggested],
    ]);
  }, [countries, countryCodes]);
  const placeholders: Map<string, string> = useMemo(() => {
    const current = getCountryName(countryCodes.current, countries, true);
    const suggested = getCountryName(countryCodes.suggested, countries, true);

    return new Map<string, string>([
      ['%c', current],
      ['%s', suggested],
    ]);
  }, [countries, countryCodes]);
  const formattedTitle = useMemo(() => {
    if (!title || !placeholders) {
      return null;
    }
    return replacePlaceholders<string>(title, placeholders);
  }, [title, placeholders]);
  const formattedBody = useMemo(() => {
    if (!body || !placeholdersBody) {
      return null;
    }
    return render(
      replacePlaceholders<RichtextStoryblok>(body, placeholdersBody)
    );
  }, [body, placeholdersBody]);
  const formattedAccept = useMemo(() => {
    if (!accept || !placeholders) {
      return null;
    }
    return replacePlaceholders<string>(accept, placeholders);
  }, [accept, placeholders]);
  const formattedReject = useMemo(() => {
    if (!reject || !placeholders) {
      return null;
    }
    return replacePlaceholders<string>(reject, placeholders);
  }, [reject, placeholders]);
  const suggestedUrl = useMemo(() => {
    const path = [
      getLocaleForCountry(countryCodes.suggested),
      ...pathname.replace(/^\//, '').split('/').splice(1),
    ].join('/');
    return `/${path}`;
  }, [countryCodes, pathname]);

  // Check location on mount
  useEffect(() => {
    checkLocation();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (portalId) {
      // const url =Nyckel:
      const container = document.querySelector(`#${CSS.escape(portalId)}`);
      if (container) {
        ref.current = container;
      }
    }
  }, [portalId]);

  if (!valid) {
    return null;
  }

  async function checkLocation() {
    // If user has already accepted or rejected suggestion, don't try again
    const localePreference = getCookie(LOCALE_PREFERENCE_COOKIE)
      ?.toLowerCase()
      .trim();
    if (!valid || localePreference) {
      return;
    }

    const response = await fetch('/api/geolocation', {
      next: { revalidate },
    });
    if (!response.ok) {
      throw new Error('Geolocation fetch failed!');
    }
    const data = await response.json();
    // Handle response
    if (data?.country_code) {
      const suggestedCountry = data.country_code.toLowerCase();

      if (locale.country_code !== suggestedCountry) {
        setCountryCodes({
          current: locale.country_code,
          suggested: suggestedCountry,
        });
        setIsOpen(true);
      }
    }
  }

  const onClickAccept = () => {
    const localePreference = getLocaleForCountry(countryCodes.suggested);
    if (localePreference) {
      setCookie(LOCALE_PREFERENCE_COOKIE, localePreference);
    }
    setIsOpen(false);
  };
  const onClickReject = () => {
    setCookie(LOCALE_PREFERENCE_COOKIE, locale.localeTag);
    setIsOpen(false);
  };
  const onClose = () => {
    setIsOpen(false);
  };

  return (
    isOpen && (
      <div className={styles.root}>
        <div className={clsx('container', styles.inner)}>
          <button className={styles.close} onClick={onClose}>
            <CloseSvg className={styles.icon} height={24} width={24} />
          </button>
          <Heading className={styles.title} variant="sub-header">
            {formattedTitle}
          </Heading>
          <div className={styles.body}>{formattedBody}</div>
          <div className={styles.actions}>
            <ButtonLargeLink
              className={styles.button}
              href={suggestedUrl}
              onClick={onClickAccept}
              variant="filled"
            >
              {formattedAccept}
            </ButtonLargeLink>
            <ButtonLarge
              className={styles.button}
              onClick={onClickReject}
              variant="dark"
            >
              {formattedReject}
            </ButtonLarge>
          </div>
        </div>
      </div>
    )
  );
};
