'use client';

import dynamic from 'next/dynamic';

import clsx from 'clsx';

import { useHeader } from '@string/context/header/use-header';
import { useLocale } from '@string/hooks/use-locale/use-locale';

import styles from './header.module.scss';

const CartToast = dynamic(() =>
  import('@string/components/cart-toast/cart-toast').then(
    (mod) => mod.CartToast
  )
);

export interface HeaderInnerProps {
  children?: React.ReactNode;
}

export const HeaderInner: React.FC<HeaderInnerProps> = ({ children }) => {
  const { isShopEnabled } = useLocale();
  const { top, hide } = useHeader();

  return (
    <header
      className={clsx(
        styles.root,
        top && styles['root--top'],
        hide && styles['root--hide']
      )}
    >
      {isShopEnabled && <CartToast />}
      {children}
    </header>
  );
};
