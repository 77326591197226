'use client';
import React, { ButtonHTMLAttributes, forwardRef, HTMLAttributes } from 'react';

import * as Accordion from '@radix-ui/react-accordion';
import clsx from 'clsx';

import { useModal } from '@string/context/modal/use-modal';
import { MenuChildData, MenuItemData } from '@string/types/menu-item-data';

import { SectionLink } from '../section-link/section-link';

import styles from './navigation-accordion.module.scss';

interface NavigationAccordionProps {
  links?: MenuItemData[];
}

export const NavigationAccordion: React.FC<NavigationAccordionProps> = ({
  links,
}) => {
  const { setOpen } = useModal();

  const onClick: React.MouseEventHandler = () => {
    setOpen(false);
  };

  const childMapper = (child: MenuChildData) => {
    const key = `NavigationAccordion-child-${child._uid}`;
    return (
      <li key={key} className={styles.child} role="none">
        <SectionLink
          className={styles.link}
          href={child.href}
          onClick={onClick}
        >
          {child.label}
        </SectionLink>
      </li>
    );
  };
  const linkMapper = (item: MenuItemData, index: number) => {
    const key = `NavigationAccordion-item-${item._uid}`;
    const value = `value-${index + 1}`;
    return (
      <Accordion.Item key={key} className={styles.item} value={value}>
        <NavigationAccordionTrigger>{item.label}</NavigationAccordionTrigger>
        <NavigationAccordionContent>
          <ul className={styles.list}>{item.links?.map(childMapper)}</ul>
        </NavigationAccordionContent>
      </Accordion.Item>
    );
  };
  return (
    <Accordion.Root className={styles.root} type="single" collapsible>
      {links?.map(linkMapper)}
    </Accordion.Root>
  );
};

const NavigationAccordionTrigger = forwardRef<
  HTMLButtonElement,
  ButtonHTMLAttributes<HTMLButtonElement>
>(({ children, className, ...props }, forwardedRef) => (
  <Accordion.Header className={clsx(styles.header)}>
    <Accordion.Trigger
      className={clsx('text__sub-header', styles.trigger, className)}
      {...props}
      ref={forwardedRef}
    >
      {children}
    </Accordion.Trigger>
  </Accordion.Header>
));

const NavigationAccordionContent = forwardRef<
  HTMLDivElement,
  HTMLAttributes<HTMLDivElement>
>(({ children, className, ...props }, forwardedRef) => (
  <Accordion.Content
    className={clsx(styles.content, className)}
    {...props}
    ref={forwardedRef}
  >
    {children}
  </Accordion.Content>
));
