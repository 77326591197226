'use client';

import { useMemo } from 'react';

import Link from 'next/link';

import clsx from 'clsx';

import { useModal } from '@string/context/modal/use-modal';
import { useLocale } from '@string/hooks/use-locale/use-locale';
import { AlgoliaHit } from '@string/types';
import { getSizes } from '@string/utils/image/get-sizes';

import { StringImage } from '../string-image/string-image';

import styles from './search-hit.module.scss';

export const SearchHit: React.FC<{ hit: AlgoliaHit }> = ({ hit }) => {
  const { language, localeTag } = useLocale();
  const { setOpen } = useModal();
  const title = useMemo(() => hit.i18n[language]?.title || '', [hit, language]);
  const href = useMemo(() => `/${localeTag}/${hit.path}`, [hit, language]);

  const sizes = getSizes([
    [0, '50vw'],
    [1024, '25vw'],
  ]);

  const onClick = () => {
    setOpen(false);
  };

  return (
    <article className={styles.root}>
      <figure className={styles.figure}>
        <StringImage alt={title} sizes={sizes} src={hit.product_image} fill />
      </figure>
      <Link
        className={clsx('text__body', styles.title)}
        href={href}
        onClick={onClick}
      >
        {title}
      </Link>
    </article>
  );
};
