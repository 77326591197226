'use client';
import { memo, useEffect, useMemo, useState } from 'react';

import clsx from 'clsx';
import useDebounce from 'react-use/lib/useDebounce';

import { useCart } from '@string/context/cart/use-cart';
import type { LineItem } from '@string/types/cart/line-item';
import type { LineItemOptionValue } from '@string/types/cart/line-item-option-value';
import { SiteLocale } from '@string/types/site';
import { normalizeLocale } from '@string/utils/normalize-locale/normalize-locale';

import { StringImage } from '../../../string-image/string-image';
import { CartItemControl } from '../cart-item-control/cart-item-control';

import styles from './cart-line-item.module.scss';

interface CartLineItemProps {
  item: LineItem;
  locale: SiteLocale;
  cartPage: boolean;
  removable?: boolean;
}
export const CartLineItem: React.FC<CartLineItemProps> = memo(
  ({ item, locale, cartPage, removable = true }) => {
    const { deleteLineItem, updateLineItem, cart } = useCart();
    const [quantity, setQuantity] = useState(item.quantity || 1);

    const [, cancel] = useDebounce(
      () => {
        if (quantity === 0) {
          deleteLineItem(item.id);
        }

        if (quantity && item.quantity && quantity !== item.quantity) {
          updateLineItem(item, quantity - item.quantity);
        }
      },
      400,
      [quantity]
    );

    useEffect(() => {
      return () => {
        cancel();
      };
    }, []);

    useEffect(() => {
      if (item.quantity && quantity !== item.quantity) {
        setQuantity(item.quantity);
      }
    }, [item]);

    const currency = useMemo(() => cart?.currency?.code || '', [cart]);
    const price = useMemo(() => {
      const value = item.sale_price ?? 0;
      const normalizedLocale = normalizeLocale(locale.localeTag);
      const regex = new RegExp(/^\w+-\w+$/);
      if (normalizedLocale && regex.test(normalizedLocale)) {
        return new Intl.NumberFormat(normalizedLocale, {
          currency,
          currencyDisplay: 'code',
          style: 'currency',
        }).format(value);
      }
      return `${value}`;
    }, [item, locale]);

    function handleAddClick() {
      if (!item.variant_id || !item.product_id || !item.quantity) {
        return;
      }
      setQuantity((current) => current + 1);
    }

    function handleSubClick() {
      if (!item.variant_id || !item.product_id || !item.quantity) {
        return;
      }

      setQuantity((current) => Math.max(current - 1, 0));
    }
    const colorString = item.options
      ?.map((item: LineItemOptionValue) => item.value)
      .join(', ');
    return (
      <article className={clsx('text__small', styles.root)}>
        {cartPage && (
          <figure className={clsx(styles.figure)}>
            <StringImage
              alt={item.name}
              height={80}
              src={item.image_url}
              width={80}
            />
          </figure>
        )}

        <div className={clsx(styles.name)}>
          <p>{item.name}</p>

          {!cartPage && (
            <span className={clsx(styles.color)}>{colorString}</span>
          )}
        </div>
        {cartPage && <div className={clsx(styles.color)}>Color</div>}
        {removable && (
          <CartItemControl
            add={handleAddClick}
            quantity={quantity}
            sub={handleSubClick}
          />
        )}
        <div className={clsx(styles.price)}>{price}</div>
      </article>
    );
  }
);
