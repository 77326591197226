'use client';

import dynamic from 'next/dynamic';

import * as NavigationMenu from '@radix-ui/react-navigation-menu';

import { type MenuItemData } from '@string/types/menu-item-data';

import styles from './desktop-menu.module.scss';

const DesktopMenuItem = dynamic(() =>
  import('../desktop-menu-item/desktop-menu-item').then(
    (mod) => mod.DesktopMenuItem
  )
);

interface DesktopMenuProps {
  links?: MenuItemData[];
}

const itemMapper = (item: MenuItemData): React.ReactNode => {
  const key = `DesktopMenu-item-${item._uid}`;
  return <DesktopMenuItem key={key} item={item} />;
};

export const DesktopMenu: React.FC<DesktopMenuProps> = ({ links = [] }) => {
  return (
    <NavigationMenu.Root className={styles.root}>
      <NavigationMenu.List key="DesktopMenu-List" className={styles.list}>
        {links.map(itemMapper)}
        <NavigationMenu.Indicator className={styles.indicator}>
          <div className={styles.arrow} />
        </NavigationMenu.Indicator>
      </NavigationMenu.List>
      <div key="DesktopMenu-Viewport" className={styles['viewport-position']}>
        <NavigationMenu.Viewport className={styles.viewport} />
      </div>
    </NavigationMenu.Root>
  );
};
