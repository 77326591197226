'use client';
import {
  forwardRef,
  memo, useId,
} from 'react';

import * as Select from '@radix-ui/react-select';
import clsx from 'clsx';

import { default as CaretDownSvg } from '@string/svg/caret-down.svg';

import styles from './input-select.module.scss';

import type { SelectItemProps } from '@radix-ui/react-select';

export interface InputSelectProps {
  children?: React.ReactNode;
  label?: string;
  className?: string;
  options: Map<string, string> | undefined;
  selected: string;
  setSelected?(selected: string): void;
  placeholder?: string;
  hasNextSibling?: boolean;
  hasPreviousSibling?: boolean;
  disabled?: boolean;
}

export const InputSelect: React.FC<InputSelectProps> = memo(({
  label,
  options,
  selected,
  setSelected,
  className,
  placeholder,
  hasNextSibling,
  hasPreviousSibling,
  disabled,
}) => {
  const uid = useId();

  // Exit early if no options
  if (!options?.size) {
    return null;
  }

  const itemMapper = ([value, label]: [string, string]) => {
    const key = ['InputSelect', uid, value, label].join('-');
    return <SelectItem key={key} value={value}>{label}</SelectItem>;
  };
  const onValueChange = (value: string) => {
    setSelected?.(value);
  };
  return (

    <Select.Root disabled={disabled} onValueChange={onValueChange} value={selected}>
      <Select.Trigger aria-label={label} className={clsx(styles.trigger, hasNextSibling && styles.next, hasPreviousSibling && styles.prev)}>
        <Select.Value placeholder={placeholder} />
        <Select.Icon className={styles.icon}>
          <CaretDownSvg
            aria-hidden={true}
            className="icon__md"
            focusable={false}
            height={20}
            width={20}
          />
        </Select.Icon>
      </Select.Trigger>
      <Select.Portal>
        <Select.Content
          className={clsx(styles.content, hasNextSibling && styles.next, hasPreviousSibling && styles.prev, className)}
          position="popper"
          side="bottom"
        >
          <Select.ScrollUpButton className={styles.scroll}>
            <CaretDownSvg />
          </Select.ScrollUpButton>
          <Select.Viewport className={styles.viewport}>
            {[...options.entries()].map(itemMapper)}
          </Select.Viewport>
          <Select.ScrollDownButton className={styles.scroll}>
            <CaretDownSvg />
          </Select.ScrollDownButton>
        </Select.Content>
      </Select.Portal>
    </Select.Root>

  );
});

const SelectItem = forwardRef<HTMLDivElement, SelectItemProps & React.RefAttributes<HTMLDivElement>>(({
  children, className, ...props
}, forwardedRef) => {
  return (
    <Select.Item className={clsx(styles.item, className)} {...props} ref={forwardedRef}>
      <Select.ItemText>{children}</Select.ItemText>
      <Select.ItemIndicator className={styles.indicator}>
        <CaretDownSvg />
      </Select.ItemIndicator>
    </Select.Item>
  );
});
