'use client';

import { useEffect, useState } from 'react';

import { usePathname, useSearchParams } from 'next/navigation';

import { GoogleTagManager, sendGTMEvent } from '@next/third-parties/google';

interface AnalyticsProps {
  gtmId: string;
}

export const Analytics: React.FC<AnalyticsProps> = ({ gtmId }) => {
  const pathname = usePathname();
  const searchParams = useSearchParams();
  const [gtmLoaded, setGtmLoaded] = useState<boolean>(false);
  const [timestamp, setTimestamp] = useState<number>(0);
  const [page_location, setPage_location] = useState<string>('');
  const [referrer, setReferrer] = useState<string>('');
  const [pollTimeout, setPollTimeout] = useState<number | undefined>(undefined);

  const callback = () => {
    if (window.dataLayer) {
      setGtmLoaded(true);
      window.clearTimeout(pollTimeout);
    }
    if (!window.dataLayer) {
      setPollTimeout((oldTimeout) => {
        if (oldTimeout) {
          window.clearTimeout(oldTimeout);
        }
        return window.setTimeout(callback, 100);
      });
    }
  };
  useEffect(() => {
    if (window.dataLayer && !gtmLoaded) {
      setGtmLoaded(true);
      if (
        !window.dataLayer.find(
          (item: any) => item?.[0] === 'consent' && item?.[1] === 'default'
        )
      ) {
        sendGTMEvent({
          0: 'consent',
          1: 'default',
          2: {
            ad_personalization: 'denied',
            ad_storage: 'denied',
            ad_user_data: 'denied',
            analytics_storage: 'granted',
            wait_for_update: 500,
          },
        });
      }
    }
    if (!window.dataLayer) {
      setPollTimeout((oldTimeout) => {
        if (oldTimeout) {
          window.clearTimeout(oldTimeout);
        }
        return window.setTimeout(callback, 100);
      });
    }
    return () => {
      if (pollTimeout) {
        window.clearTimeout(pollTimeout);
      }
    };
  }, []);

  useEffect(() => {
    if (gtmLoaded) {
      setPage_location((old_page_location) => {
        const url = new URL(window.location.href);
        url.hash = '';
        const new_page_location = url.toString();

        if (
          !old_page_location ||
          (old_page_location && old_page_location !== new_page_location)
        ) {
          setReferrer(old_page_location);
          return new_page_location;
        }
        return old_page_location;
      });
    }
  }, [gtmLoaded, pathname, searchParams]);

  useEffect(() => {
    if (page_location) {
      const pageViewEvent: Record<string, string | number> = {
        event: 'page_view',
        page_location,
      };
      if (referrer) {
        pageViewEvent.page_referrer = referrer;
      }
      if (timestamp) {
        pageViewEvent.engagement_time_msec = Date.now() - timestamp;
      }
      sendGTMEvent(pageViewEvent);
      setTimestamp(Date.now());
    }
  }, [page_location]);

  return <GoogleTagManager gtmId={gtmId} />;
};
