'use client';

import * as Dialog from '@radix-ui/react-dialog';
import clsx from 'clsx';

import { useModal } from '@string/context/modal/use-modal';
import { default as ListSvg } from '@string/svg/list.svg';
import { MenuItemData } from '@string/types/menu-item-data';

import { IconButtonRef } from '../icon-button/icon-button';
import { ModalHeader } from '../modal-header/modal-header';
import { ModalOverlay } from '../modal-overlay/modal-overlay';
import { NavigationAccordion } from '../navigation-accordion/navigation-accordion';

import styles from './touch-menu.module.scss';

interface TouchMenuProps {
  className?: string;
  triggerClassName?: string;
  style?: React.CSSProperties;
  links?: MenuItemData[];
  children?: React.ReactNode;
}

// TODO add locale selector
export const TouchMenu: React.FC<TouchMenuProps> = ({
  className,
  style,
  triggerClassName,
  links,
  children,
}) => {
  const { open, setOpen } = useModal();

  const onOpenChange = (_open: boolean) => {
    setOpen(undefined);
  };

  const title = 'Touch Menu';
  const description = 'Touch Menu description';
  const closeLabel = 'Close touch menu dialog';

  return (
    <Dialog.Root onOpenChange={onOpenChange} open={open}>
      <Dialog.Trigger asChild>
        <IconButtonRef
          aria-label={title}
          className={clsx(styles.trigger, triggerClassName)}
        >
          <ListSvg
            aria-hidden={true}
            focusable={false}
            height={24}
            width={24}
          />
        </IconButtonRef>
      </Dialog.Trigger>
      <Dialog.Portal>
        <ModalOverlay className={styles.overlay} />
        <Dialog.Content
          className={clsx(styles.content, className)}
          style={style}
        >
          <ModalHeader
            closeLabel={closeLabel}
            description={description}
            title={title}
            hideTitle
          />
          <div className={styles.inner}>
            <NavigationAccordion links={links} />
          </div>
          <div className={styles.footer}>{children}</div>
        </Dialog.Content>
      </Dialog.Portal>
    </Dialog.Root>
  );
};
